<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <BasicTop :basicTop="basicTop" numberrows="2"  v-if="appStatus == 1"/>
      <Steps
        :levels="levels"
        v-if="levels && appStatus == 1"
        :hierarchy="hierarchy"
      />
      <div class="content-show">
        <van-tabs v-model="active" :animated="true">
          <van-tab v-for="tab in tabs" name="a" :key="tab.id" :title="tab.title">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item v-for="(item, idx) in tab.collapse" :key="item.id" :title="item.title" :name="`${idx + 1}`" icon="label">
                  <van-cell-group class="group">
                    <van-cell v-for="(list, index) in item.lists" :key="index" :title="list.title" :value="`${details[list.value] || ''}`" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="附件" icon="label" :name="'3'">
                  <van-cell-group class="group">
                    <div v-for="(item, index) in details.files" :key="index">
                      <van-cell title="文件名">
                        <div>
                          <a 
                            v-if="item.title.substring(item.title.lastIndexOf('.') + 1) != 'rar' && item.title.substring(item.title.lastIndexOf('.') + 1) != 'zip'"
                            :href="'/api-ec/file/FileuploadAction/' + item.id + '-' + item.title"
                          >
                            {{ item.title }}
                          </a>
                          <span v-else @click="onCompression">{{item.title}}</span>
                        </div>
                      </van-cell>
                      <van-cell title="类型" :value="item.fileCodename" />
                    </div>
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="变更申请信息" icon="label" :name="'3'">
                  <van-cell-group class="group">
                    <div v-for="(item, index) in details.cfiles" :key="index">
                      <van-cell title="变更理由" :value="details.remark" />
                      <van-cell title="附件">
                        <div>
                          <a 
                            v-if="item.title.substring(item.title.lastIndexOf('.') + 1) != 'rar' && item.title.substring(item.title.lastIndexOf('.') + 1) != 'zip'"
                            :href="'/api-ec/file/FileuploadAction/' + item.id + '-' + item.title"
                          >
                            {{ item.title }}
                          </a>
                          <span v-else @click="onCompression">{{item.title}}</span>
                        </div>
                      </van-cell>
                      
                    </div>
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div
      class="footer"
      v-if="appStatus == 1 && showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      loadingfalg: false,
      submitflag: false,
      show: true,
      showage: true,
      title: "",
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      levels: null,
      hierarchy: "",
      basicTop: {
        data: [],
        columns: [
          {
            prop: "supeContractId",
            label: "监造合同号",
          },
          {
            prop: "manuErpContractId",
            label: "采购合同号",
          },
          {
            prop: "supeSupplierName",
            label: "监造商",
          },
          {
            prop: "manusuppliername",
            label: "制造商",
          }
        ],
      },

      tabs: [
        {
          id: 1,
          title: '详情页',
          collapse: [
            { 
              id: 1,
              title: '基本信息',
              lists: [
                {title: '采购合同号', value: 'manuErpContractId'},
                {title: '制造厂', value: 'manusuppliername'},
                {title: '监造合同号', value: 'supeContractId'},
                {title: '监造方案名称', value: 'planTopic'},
                {title: '框架协议编号', value: 'agreementCode'},
                {title: '监造商', value: 'supeSupplierName'},
                {title: '监造项目编号', value: 'supeJobno'}
              ]
            },
            { 
              id: 2,
              title: '监造物资',
              lists: [
                {title: '物资名称', value: 'prodName'},
                {title: '物资编码', value: 'prodCode'},
                {title: '数量', value: 'prodNum'},
                {title: '单位', value: 'measunit'},
                {title: '交货期', value: 'needdateSp'},
                {title: '业主单位', value: 'ownerDept'},
                {title: '项目名称', value: 'itemName'}
              ]
            },
            { 
              id: 3,
              title: '联系人信息',
              lists: [
                {title: '驻厂监造代表', value: 'manager'},
                {title: '联系电话', value: 'managerTel'},
                {title: 'Email', value: 'managerMail'},
                {title: '手机', value: 'managerMobile'}
              ]
            },
          ]
        }
      ],
    };
  },
  async created() {
    this.getStockaffirmDetail()
  },
  computed: {
    stockaffirmId() {
      return this.$route.query.id;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },
  methods: {
    async getStockaffirmDetail() {
      const params = {
        changeId: this.$route.query.changeId,
        dproSupeProjectId: this.$route.query.supeplanId,
        isChange: 1,
      };
      let data = await this.$api.ec.getProSupeProjectChangeDetail(params);

      this.title = data.planTopic
      data.needdateSp = data.needdate && data.needdate.split(" ")[0]

      if (data) {
        this.loadingfalg = false;
      }
      this.details = data;
      
      this.basicTop.data = data;
      // this.levels = Number(data.levels)
      if (this.details.fileList) {
        this.details.fileList.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
        });
      }
      if (this.details.projectFileList) {
        this.details.projectFileList.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
        });
      }
      if (this.details.enquireBillFileList) {
        this.details.enquireBillFileList.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
        });
      }
      data.wfnodeVOs.map((item, index) => {
        if (item.isNodeaction == 1) {
          this.levels = index + 1;
          return;
        }
      });
      this.hierarchy = data.wfnodeVOs.length;
    },
    ondown(item) {
      let query = {
        id: item.id,
      };
      this.$router.push({ name: "preview", query });
    },
    async auditApply(o, message) {
      let params;
      if (o == 2) {
        this.submitflag = true;
        params = {
          changeid: this.$route.query.changeId,
          procSupeProjectId: this.$route.query.supeplanId,
          audit: 0,
          leadexam: message,
        };
      } else {
        params = {
          changeid: this.$route.query.changeId,
          procSupeProjectId: this.$route.query.supeplanId,
          audit: 1,
          leadexam: "同意",
        };
      }
      let data = await this.$api.ec.auditProcSupeProjectChange(params);
      if (data) {
        Toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "ecSchemeChange",
            query: { type: 1 },
          });
        }, 1000);
      }
    },
    onClickLeft() {
      if (this.showage) {
        this.$router.push({
          name: "ecSchemeChange",
          query: { type: 1 },
        });
      } else {
        this.showage = true;
        this.title = this.details.planTopic
      }
    },
    onRefresh() {
      this.loading = true;
    },
    onCompression() {
      Dialog.alert({
        message: "压缩包请到电脑端查看",
      }).then(() => {
        // on close
      });
    },
    onLoad() {},
    listDetails() {},
    agree() {
      let messageval = "";
      if (
        this.details.ifonly == 1 &&
        this.details.stockaffirmExclusiveresultsVO &&
        this.details.stockaffirmExclusiveresultsVO.publicitytype == 1
      ) {
        messageval = "独家结果审批全部通过后将自动发出独家结果公示，是否继续?";
      } else if (
        this.details.ifonly == 1 &&
        this.details.stockaffirmExclusiveresultsVO &&
        this.details.stockaffirmExclusiveresultsVO.publicitytype == 2
      ) {
        messageval = "当前独家结果为独家采购免公示，是否继续?";
      } else {
        messageval = "是否同意";
      }
      Dialog.confirm({
        title: "审批",
        message: messageval,
      })
        .then(() => {
          this.show = false;
          this.auditApply();
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      this.title = "审批";
      this.showage = false;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.supplies {
  margin-top: 10px;
  min-height: 160px;
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 9px;
  margin: 0.3rem;
  .supplies-title {
    font-weight: 400;
    line-height: 25px;
    font-size: 13px;
  }
  .supplies-text {
    color: #8f8f8f;
    line-height: 25px;
  }
}
.record {
  margin-top: 10px;
}
</style>
